<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <div class="text">
            <router-link to="/" class="logo">
              <img src="./../assets/logoWhite.svg" class="img"/>
            </router-link>
            <div class="text-inside">
              <div class="desc small"><b>{{$t('Registry code')}}:</b> {{ $parent.settings.company_reg_number }}</div>
              <div class="desc small"><b>{{$t('Address')}}:</b> <span>{{ $parent.settings.company_address }}</span></div>
            </div>
            <ul class="list">
              <li class="item">
                <img src="./../assets/mc_symbol.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/visa.svg" class="img"/>
              </li>
              <li class="item">
                <img src="./../assets/3ds.png" class="img"/>
              </li>
              <div class="item apple-pay">
                <img src="./../assets/apple-pay.svg" class="img"/>
              </div>
              <div class="item google-pay">
                <img src="./../assets/google-pay-primary-logo-logo-svgrepo-com.svg" class="img"/>
              </div>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="text">
            <ul class="nav" v-if="$parent.textPageList.length">
              <template  v-for="item in $parent.textPageList">
                <li class="nav__item nav__item-text-pages" :key="item.id">
                  <div @click="$parent.goToPage(item.id)" class="desc small">
                    {{item.title}}
                  </div>
                </li>
              </template>
            </ul>
            <div class="desc small">
              <b>{{$t('Contact us')}}:</b>
              <div class="link">
                <b>{{ $parent.settings.support_email }}</b>
              </div>
            </div>
            <div class="links-copyright" >
              <ul>
                <li v-for="(item, i) in socials" :key="i">
                  <a :href="item.url" target="_blank">
                    <img :src="$parent.imgDomain + item.image" class="img"/>
                  </a>
                </li>
              </ul>
              <div class="desc small">{{$t('©')}} {{ new Date().getFullYear() }} {{ $parent.settings.copyright }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
      socials: []
		}
	},
	methods: {
		scrollToSection(section) {
      this.$emit('scrollToSection', section)
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 1)
      this.$emit('setActiveModel', model);
    }
	},
	mounted() {
    this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then((res) => {
        this.socials = res.data.payload
      })
      .catch(() => {
        
      })
  }
}
</script>
